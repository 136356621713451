@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

body {
  font-family: "Roboto", sans-serif;
  max-width: 100%;
  overflow-x: hidden;
}

p {
  font-size: 18px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.float-container {
  padding: 0px;
}

.float-child {
  width: 50%;
  float: left;
  padding: 3px; /*20px*/
}

.fcard {
  min-width: 533px; /*600px;*/
  min-height: 280px; /*350px*/
  border-radius: 20px;
  position: relative;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  transform-style: preserve-3d;
}

.side {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 20px 50px;
  color: #fff;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  border-radius: 20px;
}

.content {
  transform: translatez(70px) scale(0.8);
  line-height: 1em;
}
.content h1 {
  font-family: sans-serif;
  font-size: 37px;
  font-weight: 700;
  margin-top: 46px;
  position: relative;
}
.content p {
  font-family: sans-serif;
  font-size: 20px;
  margin-top: 28px;
  line-height: 1.5em;
}

.front {
  z-index: 2;
  background-size: 100vh;
  background-size: cover;
  background-color: #4e4e50;
}
.back {
  background-color: #333;
  transform: rotateX(180deg);
  z-index: 0;
  padding-top: 10px;
  background-color: #ffffff;
}

.front2 {
  z-index: 2;
  background-size: 100vh;
  background-size: cover;
  background-color: #213c57;
}
.back2 {
  background-color: #333;
  transform: rotateX(180deg);
  z-index: 0;
  padding-top: 10px;
  background-color: #ffffff;
}

/* .fcard:hover {
	-webkit-transform: rotateX(180deg);
	transform: rotateX(180deg);
} */

.back h1 {
  margin: 0;
}

/* Mobile view css for flipping cards */
.float-container1 {
  padding: 0px;
}

.float-child1 {
  width: 50%;
  float: left;
  padding: 0px; /*20px*/
}

.fcard1 {
  min-width: 337px; /* 337px for mobile previously*/ /*600px;*/
  min-height: 280px; /*350px*/
  border-radius: 20px;
  position: relative;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  transform-style: preserve-3d;
}

.side1 {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: -1px 50px;
  color: #fff;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  border-radius: 20px;
}

.content1 {
  transform: translatez(70px) scale(0.8);
  line-height: 1em;
}
.content1 h1 {
  font-family: sans-serif;
  font-size: 37px;
  font-weight: 700;
  margin-top: 46px;
  position: relative;
}
.content1 p {
  font-family: sans-serif;
  font-size: 19px;
  margin-top: 15px;
  line-height: 1.5em;
}

.front1 {
  z-index: 2;
  background-size: 100vh;
  background-size: cover;
  background-color: #4e4e50;
}
.back1 {
  background-color: #333;
  transform: rotateX(180deg);
  z-index: 0;
  padding-top: 10px;
  background-color: #ffffff;
}

.front21 {
  z-index: 2;
  background-size: 100vh;
  background-size: cover;
  background-color: #213c57;
}
.back21 {
  background-color: #333;
  transform: rotateX(180deg);
  z-index: 0;
  padding-top: 10px;
  background-color: #ffffff;
}

.fcard1:hover {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.back1 h1 {
  margin: 0;
}
/* End of mobile view for flipping cards */

/* my button style  */

.navbar-nav li:hover > ul.dropdown-menu {
  display: block;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

/* rotate caret on hover */
.dropdown-menu > li > a:hover:after {
  text-decoration: underline;
  transform: rotate(-90deg);
}

/* clients slider */
/* .slick-prev:before {
	content: "<";
	color: #1B2951 !important;
  }
  
  .slick-next:before {
	content: ">";
	color: #1B2951 !important;

  
  } */

/* end of client slider */

/* headings color */
.col-title {
  color: #1c2951;
  font-size: 2rem;
}
/* end */

@media only screen and (max-width: 929px) {
  .mobile-responsive {
    text-align: left;
  }
}
@media only screen and (min-width: 930px) {
  .mobile-responsive {
    text-align: justify;
  }
}

/* Scroll-to-top button styles */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  cursor: pointer;
  background-color: white;
  /* color: #89C000; */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 18px;
}

.scroll-to-top:hover {
  background-color: #89c000;
  color: white;
}

/* New cards after the hero and announements section */
article.AHcard {
  /* position: absolute;
    right: 50%;
    top: 50%;
    left: 50%; */
  width: 355px;
  height: 106px;
  /* transform: translate(-50%, -50%) translateZ(0); */
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.scroll {
  overflow-y: scroll;
}

article.AHcard .AHthumb {
  width: auto;
  height: 100px;
  /* background: url("../src/Assets/Img/papertopixel.png") no-repeat right; */
  background: url("../src/Assets/Img/ptop.png") no-repeat right;
  background-size: cover;
  border-radius: 2px;
  background-position: top;
  /* padding: '14px';
    border-radius: '8px';
    object-fit:'cover';
    object-position:'top right'; */
}
article.AHcard .AHthumb_New {
  width: auto;
  height: 100px;
  background: url("../src/Assets/Img/Secure-Document-Handling-M-landing-png.png") no-repeat right;
  background-size: cover;
  border-radius: 2px;
  background-position: top;
}
article.AHcard .AHthumbTwo {
  width: auto;
  height: 100px;
  background: url("../src/Assets/Img/dpvsdm.png") no-repeat right;
  background-size: cover;
  border-radius: 2px;
  background-position: top;
}
article.AHcard .AHthumbThree {
  width: auto;
  height: 100 px;
  background: url("../src/Assets/Img/tableuvsqlik_blog.jpg") no-repeat center;
  background-size: contain;
  border-radius: 2px;
}
article.AHcard .AHthumbFour {
  width: auto;
  height: 100px;
  background: url("../src/Assets/Img/FEMA.png") no-repeat center;
  background-size: contain;
  border-radius: 2px;
}
article.AHcard .infos {
  width: auto;
  height: 300px;
  position: relative;
  padding-top: 4px;
  background: #fff;
  transition: 0.4s 0.15s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}
article.AHcard .infos .AHtitle {
  position: relative;
  margin: 2px 0;
  letter-spacing: 1px;
  color: #152536;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-weight: 500;
  font-size: 1rem;
  /* text-transform: uppercase; */
  text-shadow: 0 0 0px #32577f;
  text-align: center;
}
article.AHcard .infos .AHdate,
article.AHcard .infos .afterDateClassText {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 0.85rem;
  color: rgba(21, 37, 54, 0.7);
  /* font-family: "Grotesque", sans-serif; */
}
article.AHcard .infos .afterDateClassText {
  display: inline-block;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}
article.AHcard .infos .innerTXT {
  /* font-family: "Merriweather", sans-serif; */
  line-height: 1;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.507);
  opacity: 0;
  transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}
article.AHcard .infos .details {
  position: absolute;
  left: 0;
  left: 0;
  bottom: 0;
  margin: 10px 0;
  padding: 20px 24px;
  letter-spacing: 1px;
  color: #4e958b;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-size: 0.9rem;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 0;
  transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}
article.AHcard:hover .infos {
  transform: translateY(-130px);
}
article.AHcard:hover .infos .afterDateClassText,
article.AHcard:hover .infos .innerTXT,
article.AHcard:hover .infos .details {
  opacity: 1;
}
.btn-primary1 {
  background-color: #1b2951 !important;
  color: white;
}
a.btn.btn-primary1:hover {
  color: white;
}

.overlay1 {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Mobile tab */
article.AHcard1 {
  /* position: absolute;
    right: 50%;
    top: 50%;
    left: 50%; */
  /* width: 345px; */
  height: 106px;
  /* transform: translate(-50%, -50%) translateZ(0); */
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.scroll {
  overflow-y: scroll;
}

article.AHcard1 .AHthumb_n {
  width: auto;
  height: 100px;
  /* background: url("../src/Assets/Img/papertopixel.png") no-repeat right; */
  background: url("../src/Assets/Img/Secure-Document-Handling-M-landing-png.png") no-repeat right;
  background-size: cover;
  border-radius: 2px;
  background-position: top;
  /* padding: '14px';
    border-radius: '8px';
    object-fit:'cover';
    object-position:'top right'; */
}
article.AHcard1 .AHthumb1 {
  width: auto;
  height: 100px;
  /* background: url("../src/Assets/Img/papertopixel.png") no-repeat right; */
  background: url("../src/Assets/Img/dpvsdm.png") no-repeat right;
  background-size: cover;
  border-radius: 2px;
  background-position: top;
  /* padding: '14px';
    border-radius: '8px';
    object-fit:'cover';
    object-position:'top right'; */
}
article.AHcard1 .AHthumbTwo {
  width: auto;
  height: 100px;
  background: url("../src/Assets/Img/ptop.png") no-repeat right;
  background-size: cover;
  border-radius: 2px;
  background-position: top;
}
article.AHcard1 .AHthumbThree {
  width: auto;
  height: 100 px;
  background: url("../src/Assets/Img/tableuvsqlik_blog.jpg") no-repeat center;
  background-size: contain;
  border-radius: 2px;
}
article.AHcard1 .AHthumbFour {
  width: auto;
  height: 100px;
  background: url("../src/Assets/Img/FEMA.png") no-repeat center;
  background-size: contain;
  border-radius: 2px;
}
article.AHcard1 .infos1 {
  width: auto;
  height: 300px;
  position: relative;
  padding-top: 4px;
  background: #fff;
  transition: 0.4s 0.15s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}
article.AHcard1 .infos1 .AHtitle1 {
  position: absolute;
  margin: -100px 0;
  letter-spacing: 1px;
  color: #152536;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-weight: 500;
  font-size: 1rem;
  /* text-transform: uppercase; */
  text-shadow: 0 0 0px #32577f;
  text-align: center;
}
article.AHcard1 .infos1 .AHdate,
article.AHcard1 .infos1 .afterDateClassText {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 0.85rem;
  color: rgba(21, 37, 54, 0.7);
  /* font-family: "Grotesque", sans-serif; */
}
article.AHcard11 .infos1 .afterDateClassText {
  display: inline-block;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}
article.AHcard1 .infos1 .innerTXT {
  /* font-family: "Merriweather", sans-serif; */
  line-height: 1;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.507);
  opacity: 0;
  transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}
article.AHcard1 .infos1 .details {
  position: absolute;
  left: 0;
  left: 0;
  bottom: 0;
  margin: 10px 0;
  padding: 20px 24px;
  letter-spacing: 1px;
  color: #4e958b;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-size: 0.9rem;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 0;
  transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}
article.AHcard1:hover .infos1 {
  transform: translateY(-130px);
}
article.AHcard1:hover .infos1 .afterDateClassText,
article.AHcard1:hover .infos1 .innerTXT,
article.AHcard1:hover .infos1 .details {
  opacity: 1;
}
.btn-primary1 {
  background-color: #1b2951 !important;
  color: white;
}
a.btn.btn-primary1:hover {
  color: white;
}

/* Test marquee to make the scrollable text */
.marquee {
  width: 450px;
  /* line-height: 50px; */
  /* background-color: red; */
  color: black;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

/* new card css for the research lab */
/* .container {
  background-color: #D7CCC8;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
} */
.card1 {
  background-color: #fff;
  box-shadow: 2px 2px 5px #9e9e9e, -1px -1px 5px #9e9e9e;
  border-radius: 3px;
  display: grid;
  height: 100%;
}
.card1 .img-container1 {
  width: 230px;
  height: 100%;
  grid-column: 2;
  background-color: #c1b7b4;
  background: url("../src/Assets/Img/blog_11.png") no-repeat right;
  background-size: contain;
}
.card-content1 {
  grid-column: 3 / 5;
  padding: 10px 30px;
  border-left: 1px solid #ccc;
}

.card-content1 .author1 {
  border-top: 1px solid #cdcdcd;
  font-weight: 700;
  padding: 25px 0 10px 0;
  color: #555;
}

@media only screen and (max-width: 600px) {
  .card1 {
    display: block;
  }
  .card1 .img-container1 {
    height: 250px;
    width: 100%;
  }
  .card-content1 {
    border: 0;
    border-top: 1px solid #ccc;
  }
}

/* 2nd card */
.card2 {
  background-color: #fff;
  box-shadow: 2px 2px 5px #9e9e9e, -1px -1px 5px #9e9e9e;
  border-radius: 3px;
  display: grid;
  height: 100%;
}
.card2 .img-container2 {
  width: 230px;
  height: 100%;
  grid-column: 2;
  background-color: #c1b7b4;
  background: url("../src/Assets/Img/usa1.jpg") no-repeat right;
  background-size: cover;
  background-position: center center;
}
.card-content2 {
  grid-column: 3 / 5;
  padding: 10px 30px;
  border-left: 1px solid #ccc;
}

.card-content2 .author2 {
  border-top: 1px solid #cdcdcd;
  font-weight: 700;
  padding: 25px 0 10px 0;
  color: #555;
}

@media only screen and (max-width: 600px) {
  .card2 {
    display: block;
  }
  .card2 .img-container2 {
    height: 250px;
    width: 100%;
  }
  .card-content2 {
    border: 0;
    border-top: 1px solid #ccc;
  }
}

.image-container {
  position: relative;
  width: 482px;
  height: 520px;
  overflow: hidden;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: imageTransition 8s linear infinite;
}

@keyframes imageTransition {
  0% {
    opacity: 1;
    z-index: 2;
  }
  50% {
    opacity: 0;
    z-index: 1;
  }
  100% {
    opacity: 1;
    z-index: 2;
  }
}

.image:nth-child(2) {
  animation-delay: 5s; /* Delay the animation of the second image */
}

#features {
  display: inline-block;
  height: 100%;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

#features .feature-block {
  background: #fff none repeat scroll 0 0;
  padding: 30px 20px;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out 0s;
}

#features .feature-block img {
  height: 60px;
  margin-bottom: 30px;
  width: 60px;
}

#features .feature-block:hover {
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out 0s;
}

#features .feature-block h4 {
  margin-bottom: 20px;
}

#features .feature-block p {
  margin-bottom: 0;
}

.container .z-depth-1 {
  padding-top: 10px;
}

/* .alignleft{
  --webkit-wi
} */

/* 2nd draft of a card */

/* .container-fluid1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
}
.media1 {
  background-position: center;
  background-size: cover;
  height: 100%;
  position: absolute;
  transition: all 0.3s ease;
  width: 100%;
}
figure {
  height: 200px;
  overflow: hidden;
  position: relative;
}
figure a {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}
figure:hover .media1 {
  transform: scale(1.25);
}
figcaption {
  color: #252830;
  height: calc(100% - 25px);
  margin: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 250px;
}
.body1 {
  background-color: white;
  bottom: 0;
  padding: 15px;
  position: absolute;
  width: 100%;
}
svg {
  height: inherit;
  width: 100%;
}
svg text {
  text-anchor: middle;
}
svg #alpha {
  fill: white;
}
svg .title {
  font-size: 28px;
  font-family: "Montserrat";
  letter-spacing: 5px;
}
svg #base {
  fill: white;
  -webkit-mask: url(#mask);
  mask: url(#mask);
} */
